import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { TokenDetailsBody, TokenDetailsResponse } from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const tokenUnlockContract = c.router({
  getTokenDetails: {
    method: 'GET',
    path: `token-unlock/:projectId/details`,
    responses: {
      200: TokenDetailsResponse,
    },
    pathParams: ProjectPathParam,
    summary: 'Get token details for a project',
  },
  updateTokenDetails: {
    method: 'PATCH',
    path: `token-unlock/:projectId`,
    responses: {
      200: TokenDetailsResponse,
    },
    pathParams: ProjectPathParam,
    body: TokenDetailsBody,
    summary: 'Update token details',
  },
})

export type TokenUnlockContractType = typeof tokenUnlockContract
