import type { ClientInferResponseBody } from '@ts-rest/core'
import type { contract } from '@forgd/contract'
import { useStorage } from '@vueuse/core'
import { MemberStatusEnum } from '@forgd/supabase'

type Projects = ClientInferResponseBody<
  typeof contract.projects.getProjects,
  200
>
export type User = ClientInferResponseBody<typeof contract.users.me, 200>
export type Organization = Omit<User['organizations'][number], 'projects'>
export type Project = User['organizations'][number]['projects'][number]

type OrganizationWithProjects = Organization & { projects: Project[] }

const disallowedRedirects = ['/login', '/verify-email']

export const useAuth = defineStore('auth', () => {
  const client = useClient()
  const route = useRoute()
  const supabase = useSupabase()

  const loggedIn = ref<boolean | null>(null)
  const me = ref<User | null>(null)
  const organizations = ref<OrganizationWithProjects[] | null>(null)
  const organization = ref<OrganizationWithProjects | null>(null)
  const project = ref<Project | null>(null)
  const pending = ref(false)
  const ticker = computed(() => project.value?.ticker?.toUpperCase())
  const accessTokenCookie = useCookie('forgd-access-token')
  const refreshTokenCookie = useCookie('forgd-refresh-token')
  const authSwrCache = useStorage('forgd:auth:cache', null, import.meta.client ? window.localStorage : undefined, {
    listenToStorageChanges: true,
    serializer: {
      read: (v: any) => v === null ? null : JSON.parse(v),
      write: (v: any) => JSON.stringify(v),
    },
  })

  const logToConsole = useStorage<boolean>('forgd:auth:logging', null)
  function log(...args: any[]) {
    if (import.meta.dev || logToConsole.value || me.value?.email.includes('@forgd.com')) {
      // eslint-disable-next-line no-console
      console.log('[auth]', ...args)
    }
  }
  /**
   * Helpers for authentication redirects
   * TODO: something like `redirectAuthenticatedHome` from https://github.com/forged-com/forgd/pull/1489
   */
  const dashboardPath = useRuntimeConfig().public.featureFlags.dashboard.path
  const onboardingPath = useRuntimeConfig().public.featureFlags.onboarding.path

  // watch for auth state changes to sync tabs
  watch(authSwrCache, (val) => {
    if (route.path === '/verify-email') {
      return
    }
    if (!val) {
      if (loggedIn.value) {
        logout()
      }
    }
    else if (!loggedIn.value) {
      window.location.href = dashboardPath
    }
  })
  const isOrganizationOwner = computed(() => organization.value?.ownerUserId === me.value?.id)

  const shouldAutoLogin = ref(true)
  // if this exists then we're authenticating
  const authCheckPromise: Ref<Promise<boolean> | null> = ref(null)

  async function doAuthFetch() {
    pending.value = true
    const mePayload = await client.users.me().catch((err) => {
      console.error('Auth failed', err)
      return null
    }).finally(() => {
      pending.value = false
    })
    if (mePayload?.status !== 200) {
      if (route.path !== '/login') {
        window.location.href = '/login?action=logout'
      }
      return false
    }

    loggedIn.value = true
    me.value = mePayload.body
    organizations.value = (me.value.organizations || []) as any as OrganizationWithProjects[]

    let _project = project.value
    let _organization = organization.value
    // we have hydrated project and organization from the cache HOWEVER they may not match the new payload
    // so we select the org and project IF they match, otherwise fallback to the first available active project
    if (_project || _organization) {
      let matched = false
      for (const org of mePayload.body.organizations || []) {
        for (const p of org.projects || []) {
          if (p.id === _project?.id && org.id === _organization?.id) {
            _project = p
            _organization = org
            matched = true
          }
        }
      }
      if (!matched) {
        _project = null
        _organization = null
      }
    }

    // avoid using any stale data for hydration
    organization.value = _organization || organizations.value?.find(organization => organization.memberStatus === 'active') || organizations.value?.[0]
    project.value = _project || organization.value.projects?.[0]
    authSwrCache.value = {
      me: me.value,
      projectId: project.value?.id,
    }
    return true
  }

  async function refresh(options: { from?: string }) {
    log('refresh', options)
    await doAuthFetch()
  }

  // opt-in swr hydration of auth payload
  async function check(options?: { from?: string, swr?: boolean, onFailure?: () => Promise<void> | void, redirect?: boolean }) {
    log('check', options)
    // avoid multiple auth checks running at once
    if (authCheckPromise.value) {
      log('authCheckPromise already exists')
      return authCheckPromise.value
    }
    return authCheckPromise.value = new Promise<boolean>((resolve) => {
      // we apply SWR logic to authentication
      if (options?.swr && authSwrCache.value) {
        log('hydrating from cache')
        // hydrate from payload
        const { me: _me, projectId } = authSwrCache.value
        me.value = _me
        organizations.value = _me.organizations
        const _project = _me.organizations?.flatMap(o => o.projects)?.find(p => p.id === projectId)
        organization.value = organizations.value?.find(o => o.id === _project?.organizationId)
        project.value = _project
        loggedIn.value = true
        // do the auth check async, don't block the user
        doAuthFetch().then((res) => {
          !res && options?.onFailure?.()
          if (options?.redirect) {
            redirect({ from: options.from })
          }
        })
        return resolve(true)
      }
      doAuthFetch().then(async (res) => {
        !res && await options?.onFailure?.()
        if (options?.redirect) {
          await redirect({ from: options.from })
        }
        resolve(res)
      })
    }).finally(() => {
      authCheckPromise.value = null
    })
  }

  /**
   * Note: this needs to be called in addition to the Supabase signOut function
   */
  function clear() {
    loggedIn.value = false
    // full clean up of args to avoid stale data when switching accounts
    me.value = null
    project.value = null
    organization.value = null
    organizations.value = null
    shouldAutoLogin.value = false
    authSwrCache.value = null
    accessTokenCookie.value = null
    refreshTokenCookie.value = null
    refreshCookie('forgd-access-token')
    refreshCookie('forgd-refresh-token')
  }

  async function switchProject(newProject: Projects) {
    log('switchProject', newProject)
    project.value = newProject
    authSwrCache.value = {
      me: me.value,
      projectId: project.value?.id,
    }
    // TODO hacky way to clear the state while we don't handle reactivity globally yet
    await nextTick(() => {
      window.location.reload()
    })
  }

  // We need to do this to avoid any reactive updates for the page the user is on,
  // this means we should be able to guarantee the user exists in an authenticated page
  async function logout() {
    // Logout is a multi-step process:
    // 1. sign out via supabase, this can take a second so we keep the loading state
    // 2. navigate to the login page with a special query param to indicate logout
    // 3. on the login page we check for this param and clear the persisted state
    await supabase.auth.signOut()
      .then(() => {
        // an external navigation will clear some state but not the data in localStorage
        // so we need to give the login page a hint to clear the localStorage data
        navigateTo('/login?action=logout', { external: true })
      })
  }

  async function redirect(options: { from?: string }) {
    log('redirect', options)

    if (!loggedIn.value) {
      log('redirecting to login')
      // unsure why external is needed here but it is
      await navigateTo('/login', { external: true })
      return
    }

    if (me.value?.organizations?.length) {
      /*
      * There are 6 scenarios:
      * 1. User has OWN memberStatus=active organization with no onboarded projects (no projects at all) → redirect to owner onboarding
      * 2. User has OWN memberStatus=active organization with an onboarded project → redirect to dashboard
      * 3. User has INVITED memberStatus=active organization with no onboarded projects → redirect to teammate onboarding with organizationId
      * 4. User has INVITED memberStatus=active organization with an onboarded project → redirect to dashboard
      * 5. There's route.query.organizationId → redirect to teammate onboarding with organizationId
      * 6. User has INVITED memberStatus=invited organization → redirect to teammate onboarding with organizationId
      * */

      // case when we need to redirect to teammate onboarding with organizationId (5)
      if (route.query.organizationId) {
        log('redirecting to onboarding')
        await navigateTo({
          path: onboardingPath,
          query: {
            organizationId: route.query.organizationId,
          },
        })
        return
      }

      // case when we need to redirect to teammate onboarding with organizationId (6)
      const invitedOrganization = me.value?.organizations.find(
        org => org.memberStatus === MemberStatusEnum.Invited && org.ownerUserId !== me.value?.id,
      )
      if (invitedOrganization) {
        log('redirecting to onboarding')
        await navigateTo({
          path: onboardingPath,
          query: {
            organizationId: invitedOrganization.id,
          },
        })
        return
      }

      // case when we need to redirect to teammate onboarding with organizationId (3)
      const activeInvitedOrganization = me.value?.organizations.find(
        org => org.memberStatus === MemberStatusEnum.Active && org.ownerUserId !== me.value?.id && !org.projects.length,
      )
      if (activeInvitedOrganization) {
        log('redirecting to onboarding')
        await navigateTo({
          path: onboardingPath,
          query: {
            organizationId: activeInvitedOrganization.id,
          },
        })
        return
      }

      // case when we need to redirect to owner onboarding (1)
      const activeOwnOrganizationWithoutOnboardedProjects = me.value?.organizations.find(
        org => org.memberStatus === MemberStatusEnum.Active && org.ownerUserId === me.value?.id && (!org.projects.length || org.projects.some(p => !p.isOnboarded)),
      )
      if (activeOwnOrganizationWithoutOnboardedProjects) {
        log('redirecting to onboarding')
        await navigateTo(onboardingPath)
        return
      }

      // case when we need to redirect to dashboard (2, 4)
      const activeOrganizationWithOnboardedProject = me.value?.organizations.find(
        org => org.memberStatus === MemberStatusEnum.Active && org.projects.length,
      )
      if (activeOrganizationWithOnboardedProject) {
        log('redirecting to', redirectTo.value || dashboardPath)
        await navigateTo(redirectTo.value || dashboardPath)
        redirectTo.value = null
      }
    }
  }

  function setRedirectTo(path: string) {
    if (disallowedRedirects.includes(path)) {
      log('disallowed post-auth redirect', path)
      return
    }
    log('setting post-auth redirect', path)
    redirectTo.value = path
  }

  const redirectTo = ref<string | null>(null)

  return {
    organization,
    organizations,
    check,
    switchProject,
    loggedIn,
    me,
    isOrganizationOwner,
    project,
    shouldAutoLogin,
    ticker,
    redirectTo,
    setRedirectTo,
    clear,
    logout,
    pending,
    dashboardPath,
    onboardingPath,
    refresh,
    redirect,
    log,
  }
})
