import { z } from 'zod'
import { TProject, TUpdateProjectBody } from '../projects'
import { MemberContactType, MemberInvitationDto } from '../membership'
import {
  AreasOfInterestEnum,
  BaseLayerTypeEnum,
  LeadSourceTypeEnum,
  SectorTypeEnum,
} from '@forgd/supabase'

export const InitProjectBody = z.object({
  organizationId: z.string().uuid(),
  isResearch: z.boolean(),
})
export const InitProjectResponse = TProject

export const UpdateProjectTypeBody = z.object({
  projectId: z.string().uuid(),
  isResearch: z.boolean(),
})

export const UpdateProjectTypeResponse = TProject

const areasOfInterestEnum = z.nativeEnum(AreasOfInterestEnum)

export const UpdateProjectDetailsBody = TUpdateProjectBody.extend({
  tokenListed: z.boolean(),
}).refine(
  (data) => {
    if (data.coingeckoId == undefined || data.coingeckoId === null) {
      // skip the validation when the client updates other fields.
      return true
    }
    if (data.tokenListed) {
      return data.coingeckoId && data.coingeckoId.trim().length > 0
    } else {
      return !data.coingeckoId
    }
  },
  {
    message:
      'Please provide a valid CoinGecko ID when the token is listed, or set it null if the token is not listed.',
    path: ['coingeckoId'],
  },
)

export const UpdateAreasOfInterestBody = z.object({
  projectId: z.string().uuid(),
  areasOfInterest: z.array(areasOfInterestEnum),
})

export const OwnerDetailsBody = z.object({
  projectId: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  organizationName: z.string(),
  position: z.string(),
  contactType: z.nativeEnum(MemberContactType),
  contact: z.string().min(1),
  leadSourceType: z.nativeEnum(LeadSourceTypeEnum),
})

export const CompleteBody = z.object({
  projectId: z.string().uuid(),
  members: z.array(MemberInvitationDto),
})

export const GetProgressResponseBody = z.object({
  projectType: z.boolean(),
  tokenListed: z.boolean(),
  projectDetails: z.boolean(),
  areasOfInterest: z.boolean(),
  ownerDetails: z.boolean(),
  memberInvitations: z.boolean(),
})

export const MemberDetailsBody = OwnerDetailsBody.omit({
  leadSourceType: true,
}).extend({
  email: z
    .string()
    .email()
    .transform((email) => email.toLowerCase()),
})

export const GetMemberInvitesResponseBody = z.object({
  members: z.array(MemberDetailsBody),
  maxSeats: z.number(),
})

export const UpdateListedProjectBody = z.object({
  coingeckoId: z.string(),
  image: z.string(),
  name: z.string().trim().min(1),
  ticker: z
    .string()
    .trim()
    .min(1)
    .transform((val) => val.toUpperCase()),
  tgeDate: z
    .string()
    .transform((val) => new Date(val))
    .optional(),
  link: z.string().url(),
  sector: z.array(z.nativeEnum(SectorTypeEnum)).optional(),
  baseLayer: z.array(z.nativeEnum(BaseLayerTypeEnum)).optional(),
})

const nullableString = z
  .string()
  .transform((val) => (!val ? null : val))
  .optional()

export const UpdateNotListedProjectBody = z.object({
  coingeckoId: z
    .string()
    .nullable()
    .transform((val) => (!val ? null : val)),
  image: nullableString,
  name: z.string().trim().min(1),
  ticker: z
    .string()
    .nullable()
    .transform((val) => {
      if (!val || val.trim() === '') {
        return null
      }
      return val.toUpperCase()
    }),
  tgeDate: z
    .string()
    .nullable()
    .transform((val) => {
      if (!val) {
        return null
      }
      const date = new Date(val)
      return isNaN(date.getTime()) ? null : date
    }),
  link: z
    .union([z.string().url(), z.string().nullable(), z.string().length(0)])
    .transform((val) => {
      if (!val || val.trim() === '') {
        return null
      }

      return val
    }),
  sector: z.array(z.nativeEnum(SectorTypeEnum)).optional(),
  baseLayer: z.array(z.nativeEnum(BaseLayerTypeEnum)).optional(),
})

export const GetMemberDetailsResponseBody = MemberDetailsBody

export const GetOwnerDetailsResponseBody = OwnerDetailsBody

export const GetAreasOfInterestResponseBody = UpdateAreasOfInterestBody

export const GetProjectDetailsResponseBody = z.union([TProject, z.null()])

export type OwnerDetails = z.infer<typeof OwnerDetailsBody>
export type MemberDetails = z.infer<typeof MemberDetailsBody>
export type OnboardingProgress = z.infer<typeof GetProgressResponseBody>
