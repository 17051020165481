import { type Ref, type Slots, unref } from 'vue'
import type { ComponentUi } from '~/utils/theme'

// ---------------------------------------------------------------------------------------------------------------------
// props
// ---------------------------------------------------------------------------------------------------------------------

// Constraint for components with $props
type ComponentWithProps = new (...args: any[]) => ComponentPublicInstance & { $props: Record<string, any> }

// Generic type to extract props from any component
export type PropsOf<T extends ComponentWithProps> = InstanceType<T>['$props']

type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
}

/**
 * Type-safe function to build a props object for the base component
 *
 * @param callback    A callback with the signature
 */
export function makeProps<Base extends ComponentWithProps>(
  callback: (output: Partial<Mutable<PropsOf<Base>>>) => Partial<PropsOf<Base>>,
) {
  return callback({})
}

// ---------------------------------------------------------------------------------------------------------------------
// component ui props
// ---------------------------------------------------------------------------------------------------------------------


/**
 * Helper function to type-hint NuxtUI `ui` props options
 *
 * @param name      The component name
 * @param options   The component options
 *
 * @usage
 *
 *    const ui = defineUI('popover', { ... })
 */
export function defineUi<K extends keyof ComponentUi>(name: K, options: ComponentUi[K]): ComponentUi[K] {
  // trim and format passed options
  function clean(target: Record<string, any>): void {
    const keys = Object.keys(target)
    for (const key of keys) {
      const value = target[key]
      if (value && typeof value === 'object') {
        clean(value)
      }
      else if (typeof target[key] === 'string') {
        target[key] = value.trim().replace(/\s+/g, '\n')
      }
    }
  }

  // clean spaces
  clean(options as any)

  // return
  return options
}

// ---------------------------------------------------------------------------------------------------------------------
// sizes
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Utility function to return a hash of { size: prop-value } config
 *
 * @param sizes
 * @param prop
 * @param values
 *
 * @usage
 *
 *    getSized(['sm', 'md', 'lg'] as const, 'text', 1, 2, 3)
 *
 *    const sizes = {
 *        sm: 'text-1',
 *        md: 'text-2',
 *        lg: 'text-3',
 *    }
 */
export function getSized<
  Sizes extends string[],
  Config extends { [K in Sizes[number]]: string },
>(
  sizes: [...Sizes],
  prop: string,
  ...values: number[]
): Config {
  return sizes.reduce((output, size, index: number) => {
    const value = index < values.length
      ? values[index]
      : values.at(-1)
    ;(output as any)[size] = `${prop}-${value}`
    return output
  }, {} as Config)
}

// ---------------------------------------------------------------------------------------------------------------------
// classes
// ---------------------------------------------------------------------------------------------------------------------

type ClassValue = string | { [key: string]: any } | ClassValue[]
type MaybeRef<T> = T | Ref<T>
type ClassOption = MaybeRef<ClassValue>

export function getClasses(...options: ClassOption[]): string {
  // Use a Set to store unique classes
  const classSet = new Set<string>()

  function addClasses(option: ClassValue) {
    if (typeof option === 'string') {
      option.split(/\s+/).forEach(cls => cls && classSet.add(cls.trim()))
    }
    else if (Array.isArray(option)) {
      option.forEach(addClasses)
    }
    else if (typeof option === 'object' && option !== null) {
      Object.entries(option).forEach(([key, value]) => {
        if (unref(value)) {
          classSet.add(key.trim())
        }
      })
    }
  }

  // add options
  options.forEach((option) => {
    const unwrapped = unref(option)
    addClasses(unwrapped)
  })

  // Convert Set to array and join with spaces
  return Array.from(classSet).join(' ')
}

// ---------------------------------------------------------------------------------------------------------------------
// event handlers
// ---------------------------------------------------------------------------------------------------------------------

interface EventHandlers {
  [key: string]: (...args: any[]) => void
}

type EmitFn = (name: string, ...args: any[]) => void

export function getHandlers(eventNames: string[], emit: EmitFn): EventHandlers {
  return eventNames.reduce<EventHandlers>((handlers, eventName) => {
    const handlerName = `on${eventName.charAt(0).toUpperCase()}${eventName.slice(1)}`
    handlers[handlerName] = (...args: any[]) => emit(eventName, ...args)
    return handlers
  }, {})
}

// ---------------------------------------------------------------------------------------------------------------------
// content
// ---------------------------------------------------------------------------------------------------------------------

export function getContent<P extends Record<string, any>>(slots: Slots, props: P, name: keyof P) {
  const ref = toRef(props, name)
  return computed(() => slots.default
    ? slots.default()
    : ref.value)
}

// ---------------------------------------------------------------------------------------------------------------------
// icons
// ---------------------------------------------------------------------------------------------------------------------

export type IconName =
  | string
  | 'i-heroicons-academic-cap'
  | 'i-heroicons-adjustments-horizontal'
  | 'i-heroicons-adjustments-vertical'
  | 'i-heroicons-archive-box-arrow-down'
  | 'i-heroicons-archive-box-x-mark'
  | 'i-heroicons-archive-box'
  | 'i-heroicons-arrow-down-circle'
  | 'i-heroicons-arrow-down-left'
  | 'i-heroicons-arrow-down-on-square-stack'
  | 'i-heroicons-arrow-down-on-square'
  | 'i-heroicons-arrow-down-right'
  | 'i-heroicons-arrow-down-tray'
  | 'i-heroicons-arrow-down'
  | 'i-heroicons-arrow-left-circle'
  | 'i-heroicons-arrow-left-end-on-rectangle'
  | 'i-heroicons-arrow-left-start-on-rectangle'
  | 'i-heroicons-arrow-left'
  | 'i-heroicons-arrow-long-down'
  | 'i-heroicons-arrow-long-left'
  | 'i-heroicons-arrow-long-right'
  | 'i-heroicons-arrow-long-up'
  | 'i-heroicons-arrow-path-rounded-square'
  | 'i-heroicons-arrow-path'
  | 'i-heroicons-arrow-right-circle'
  | 'i-heroicons-arrow-right-end-on-rectangle'
  | 'i-heroicons-arrow-right-start-on-rectangle'
  | 'i-heroicons-arrow-right'
  | 'i-heroicons-arrow-top-right-on-square'
  | 'i-heroicons-arrow-trending-down'
  | 'i-heroicons-arrow-trending-up'
  | 'i-heroicons-arrow-turn-down-left'
  | 'i-heroicons-arrow-turn-down-right'
  | 'i-heroicons-arrow-turn-left-down'
  | 'i-heroicons-arrow-turn-left-up'
  | 'i-heroicons-arrow-turn-right-down'
  | 'i-heroicons-arrow-turn-right-up'
  | 'i-heroicons-arrow-turn-up-left'
  | 'i-heroicons-arrow-turn-up-right'
  | 'i-heroicons-arrow-up-circle'
  | 'i-heroicons-arrow-up-left'
  | 'i-heroicons-arrow-up-on-square-stack'
  | 'i-heroicons-arrow-up-on-square'
  | 'i-heroicons-arrow-up-right'
  | 'i-heroicons-arrow-up-tray'
  | 'i-heroicons-arrow-up'
  | 'i-heroicons-arrow-uturn-down'
  | 'i-heroicons-arrow-uturn-left'
  | 'i-heroicons-arrow-uturn-right'
  | 'i-heroicons-arrow-uturn-up'
  | 'i-heroicons-arrows-pointing-in'
  | 'i-heroicons-arrows-pointing-out'
  | 'i-heroicons-arrows-right-left'
  | 'i-heroicons-arrows-up-down'
  | 'i-heroicons-at-symbol'
  | 'i-heroicons-backspace'
  | 'i-heroicons-backward'
  | 'i-heroicons-banknotes'
  | 'i-heroicons-bars-2'
  | 'i-heroicons-bars-3-bottom-left'
  | 'i-heroicons-bars-3-bottom-right'
  | 'i-heroicons-bars-3-center-left'
  | 'i-heroicons-bars-3'
  | 'i-heroicons-bars-4'
  | 'i-heroicons-bars-arrow-down'
  | 'i-heroicons-bars-arrow-up'
  | 'i-heroicons-battery-0'
  | 'i-heroicons-battery-100'
  | 'i-heroicons-battery-50'
  | 'i-heroicons-beaker'
  | 'i-heroicons-bell-alert'
  | 'i-heroicons-bell-slash'
  | 'i-heroicons-bell-snooze'
  | 'i-heroicons-bell'
  | 'i-heroicons-bold'
  | 'i-heroicons-bolt-slash'
  | 'i-heroicons-bolt'
  | 'i-heroicons-book-open'
  | 'i-heroicons-bookmark-slash'
  | 'i-heroicons-bookmark-square'
  | 'i-heroicons-bookmark'
  | 'i-heroicons-briefcase'
  | 'i-heroicons-bug-ant'
  | 'i-heroicons-building-library'
  | 'i-heroicons-building-office-2'
  | 'i-heroicons-building-office'
  | 'i-heroicons-building-storefront'
  | 'i-heroicons-cake'
  | 'i-heroicons-calculator'
  | 'i-heroicons-calendar-date-range'
  | 'i-heroicons-calendar-days'
  | 'i-heroicons-calendar'
  | 'i-heroicons-camera'
  | 'i-heroicons-chart-bar-square'
  | 'i-heroicons-chart-bar'
  | 'i-heroicons-chart-pie'
  | 'i-heroicons-chat-bubble-bottom-center-text'
  | 'i-heroicons-chat-bubble-bottom-center'
  | 'i-heroicons-chat-bubble-left-ellipsis'
  | 'i-heroicons-chat-bubble-left-right'
  | 'i-heroicons-chat-bubble-left'
  | 'i-heroicons-chat-bubble-oval-left-ellipsis'
  | 'i-heroicons-chat-bubble-oval-left'
  | 'i-heroicons-check-badge'
  | 'i-heroicons-check-circle'
  | 'i-heroicons-check'
  | 'i-heroicons-chevron-double-down'
  | 'i-heroicons-chevron-double-left'
  | 'i-heroicons-chevron-double-right'
  | 'i-heroicons-chevron-double-up'
  | 'i-heroicons-chevron-down'
  | 'i-heroicons-chevron-left'
  | 'i-heroicons-chevron-right'
  | 'i-heroicons-chevron-up-down'
  | 'i-heroicons-chevron-up'
  | 'i-heroicons-circle-stack'
  | 'i-heroicons-clipboard-document-check'
  | 'i-heroicons-clipboard-document-list'
  | 'i-heroicons-clipboard-document'
  | 'i-heroicons-clipboard'
  | 'i-heroicons-clock'
  | 'i-heroicons-cloud-arrow-down'
  | 'i-heroicons-cloud-arrow-up'
  | 'i-heroicons-cloud'
  | 'i-heroicons-code-bracket-square'
  | 'i-heroicons-code-bracket'
  | 'i-heroicons-cog-6-tooth'
  | 'i-heroicons-cog-8-tooth'
  | 'i-heroicons-cog'
  | 'i-heroicons-command-line'
  | 'i-heroicons-computer-desktop'
  | 'i-heroicons-cpu-chip'
  | 'i-heroicons-credit-card'
  | 'i-heroicons-cube-transparent'
  | 'i-heroicons-cube'
  | 'i-heroicons-currency-bangladeshi'
  | 'i-heroicons-currency-dollar'
  | 'i-heroicons-currency-euro'
  | 'i-heroicons-currency-pound'
  | 'i-heroicons-currency-rupee'
  | 'i-heroicons-currency-yen'
  | 'i-heroicons-cursor-arrow-rays'
  | 'i-heroicons-cursor-arrow-ripple'
  | 'i-heroicons-device-phone-mobile'
  | 'i-heroicons-device-tablet'
  | 'i-heroicons-divide'
  | 'i-heroicons-document-arrow-down'
  | 'i-heroicons-document-arrow-up'
  | 'i-heroicons-document-chart-bar'
  | 'i-heroicons-document-check'
  | 'i-heroicons-document-currency-bangladeshi'
  | 'i-heroicons-document-currency-dollar'
  | 'i-heroicons-document-currency-euro'
  | 'i-heroicons-document-currency-pound'
  | 'i-heroicons-document-currency-rupee'
  | 'i-heroicons-document-currency-yen'
  | 'i-heroicons-document-duplicate'
  | 'i-heroicons-document-magnifying-glass'
  | 'i-heroicons-document-minus'
  | 'i-heroicons-document-plus'
  | 'i-heroicons-document-text'
  | 'i-heroicons-document'
  | 'i-heroicons-ellipsis-horizontal-circle'
  | 'i-heroicons-ellipsis-horizontal'
  | 'i-heroicons-ellipsis-vertical'
  | 'i-heroicons-envelope-open'
  | 'i-heroicons-envelope'
  | 'i-heroicons-equals'
  | 'i-heroicons-exclamation-circle'
  | 'i-heroicons-exclamation-triangle'
  | 'i-heroicons-eye-dropper'
  | 'i-heroicons-eye-slash'
  | 'i-heroicons-eye'
  | 'i-heroicons-face-frown'
  | 'i-heroicons-face-smile'
  | 'i-heroicons-film'
  | 'i-heroicons-finger-print'
  | 'i-heroicons-fire'
  | 'i-heroicons-flag'
  | 'i-heroicons-folder-arrow-down'
  | 'i-heroicons-folder-minus'
  | 'i-heroicons-folder-open'
  | 'i-heroicons-folder-plus'
  | 'i-heroicons-folder'
  | 'i-heroicons-forward'
  | 'i-heroicons-funnel'
  | 'i-heroicons-gif'
  | 'i-heroicons-gift-top'
  | 'i-heroicons-gift'
  | 'i-heroicons-globe-alt'
  | 'i-heroicons-globe-americas'
  | 'i-heroicons-globe-asia-australia'
  | 'i-heroicons-globe-europe-africa'
  | 'i-heroicons-h1'
  | 'i-heroicons-h2'
  | 'i-heroicons-h3'
  | 'i-heroicons-hand-raised'
  | 'i-heroicons-hand-thumb-down'
  | 'i-heroicons-hand-thumb-up'
  | 'i-heroicons-hashtag'
  | 'i-heroicons-heart'
  | 'i-heroicons-home-modern'
  | 'i-heroicons-home'
  | 'i-heroicons-identification'
  | 'i-heroicons-inbox-arrow-down'
  | 'i-heroicons-inbox-stack'
  | 'i-heroicons-inbox'
  | 'i-heroicons-information-circle'
  | 'i-heroicons-italic'
  | 'i-heroicons-key'
  | 'i-heroicons-language'
  | 'i-heroicons-lifebuoy'
  | 'i-heroicons-light-bulb'
  | 'i-heroicons-link-slash'
  | 'i-heroicons-link'
  | 'i-heroicons-list-bullet'
  | 'i-heroicons-lock-closed'
  | 'i-heroicons-lock-open'
  | 'i-heroicons-magnifying-glass-circle'
  | 'i-heroicons-magnifying-glass-minus'
  | 'i-heroicons-magnifying-glass-plus'
  | 'i-heroicons-magnifying-glass'
  | 'i-heroicons-map-pin'
  | 'i-heroicons-map'
  | 'i-heroicons-megaphone'
  | 'i-heroicons-microphone'
  | 'i-heroicons-minus-circle'
  | 'i-heroicons-minus'
  | 'i-heroicons-moon'
  | 'i-heroicons-musical-note'
  | 'i-heroicons-newspaper'
  | 'i-heroicons-no-symbol'
  | 'i-heroicons-numbered-list'
  | 'i-heroicons-paint-brush'
  | 'i-heroicons-paper-airplane'
  | 'i-heroicons-paper-clip'
  | 'i-heroicons-pause-circle'
  | 'i-heroicons-pause'
  | 'i-heroicons-pencil-square'
  | 'i-heroicons-pencil'
  | 'i-heroicons-percent-badge'
  | 'i-heroicons-phone-arrow-down-left'
  | 'i-heroicons-phone-arrow-up-right'
  | 'i-heroicons-phone-x-mark'
  | 'i-heroicons-phone'
  | 'i-heroicons-photo'
  | 'i-heroicons-play-circle'
  | 'i-heroicons-play-pause'
  | 'i-heroicons-play'
  | 'i-heroicons-plus-circle'
  | 'i-heroicons-plus'
  | 'i-heroicons-power'
  | 'i-heroicons-presentation-chart-bar'
  | 'i-heroicons-presentation-chart-line'
  | 'i-heroicons-printer'
  | 'i-heroicons-puzzle-piece'
  | 'i-heroicons-qr-code'
  | 'i-heroicons-question-mark-circle'
  | 'i-heroicons-queue-list'
  | 'i-heroicons-radio'
  | 'i-heroicons-receipt-percent'
  | 'i-heroicons-receipt-refund'
  | 'i-heroicons-rectangle-group'
  | 'i-heroicons-rectangle-stack'
  | 'i-heroicons-rocket-launch'
  | 'i-heroicons-rss'
  | 'i-heroicons-scale'
  | 'i-heroicons-scissors'
  | 'i-heroicons-server-stack'
  | 'i-heroicons-server'
  | 'i-heroicons-share'
  | 'i-heroicons-shield-check'
  | 'i-heroicons-shield-exclamation'
  | 'i-heroicons-shopping-bag'
  | 'i-heroicons-shopping-cart'
  | 'i-heroicons-signal-slash'
  | 'i-heroicons-signal'
  | 'i-heroicons-slash'
  | 'i-heroicons-sparkles'
  | 'i-heroicons-speaker-wave'
  | 'i-heroicons-speaker-x-mark'
  | 'i-heroicons-square-2-stack'
  | 'i-heroicons-square-3-stack-3d'
  | 'i-heroicons-squares-2x2'
  | 'i-heroicons-squares-plus'
  | 'i-heroicons-star'
  | 'i-heroicons-stop-circle'
  | 'i-heroicons-stop'
  | 'i-heroicons-strikethrough'
  | 'i-heroicons-sun'
  | 'i-heroicons-swatch'
  | 'i-heroicons-table-cells'
  | 'i-heroicons-tag'
  | 'i-heroicons-ticket'
  | 'i-heroicons-trash'
  | 'i-heroicons-trophy'
  | 'i-heroicons-truck'
  | 'i-heroicons-tv'
  | 'i-heroicons-underline'
  | 'i-heroicons-user-circle'
  | 'i-heroicons-user-group'
  | 'i-heroicons-user-minus'
  | 'i-heroicons-user-plus'
  | 'i-heroicons-user'
  | 'i-heroicons-users'
  | 'i-heroicons-variable'
  | 'i-heroicons-video-camera-slash'
  | 'i-heroicons-video-camera'
  | 'i-heroicons-view-columns'
  | 'i-heroicons-viewfinder-circle'
  | 'i-heroicons-wallet'
  | 'i-heroicons-wifi'
  | 'i-heroicons-window'
  | 'i-heroicons-wrench-screwdriver'
  | 'i-heroicons-wrench'
  | 'i-heroicons-x-circle'
  | 'i-heroicons-x-mark'

// ---------------------------------------------------------------------------------------------------------------------
// temp
// ---------------------------------------------------------------------------------------------------------------------

/*
import type { AllowedComponentProps, ComponentCustomProps, ComponentPublicInstance, VNodeProps } from 'vue'
import { UInput } from '#components'

type ExtractComponentProps<T> = T extends new () => ComponentPublicInstance<infer P>
  ? Omit<P, keyof VNodeProps | keyof AllowedComponentProps | keyof ComponentCustomProps>
  : never

type Props = ExtractComponentProps<typeof UInput>

function extendComponent<Base extends any>(
  base: Base,
  props: ExtractComponentProps<Base>,
) {

}
*/
