import { z } from 'zod'
import { customDateSchema } from '../common'

export const PriceDataSchema = z.object({
  date: customDateSchema,
  value: z.number(),
})

export const MetaSchema = z.object({
  xAxis: z.string(),
  labels: z.record(z.any()),
})

export const TokenDetailsResponse = z.object({
  tgeDate: customDateSchema,
  coingeckoId: z.string(),
  maxTokenSupply: z.number(),
  daysSinceTGE: z.number(),
  price: z.number(),
  priceChange24h: z.number(),
  fullyDilutedValuation: z.number(),
  marketChartPrice24h: z.object({
    data: z.array(PriceDataSchema),
    meta: MetaSchema,
  }),
  circulatingTokenSupply: z.number(),
  marketCap: z.number(),
})

export const TokenDetailsBody = z.object({
  tgeDate: customDateSchema,
  coingeckoId: z.string(),
  maxTokenSupply: z.number(),
})

export type TokenDetails = z.infer<typeof TokenDetailsBody>
