export default {
  app: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/app.vue").then(m => m.default || m),
  default: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/default.vue").then(m => m.default || m),
  landing: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/landing.vue").then(m => m.default || m),
  legal: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/legal.vue").then(m => m.default || m),
  public: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/public.vue").then(m => m.default || m),
  dashboard: () => import("/opt/render/project/src/apps/ui/core/layouts/dashboard.vue").then(m => m.default || m),
  home: () => import("/opt/render/project/src/apps/ui/core/layouts/home.vue").then(m => m.default || m),
  auth: () => import("/opt/render/project/src/apps/ui/layers/auth/layouts/auth.vue").then(m => m.default || m),
  "onboarding-v2": () => import("/opt/render/project/src/apps/ui/layers/account/layouts/onboarding-v2.vue").then(m => m.default || m),
  onboarding: () => import("/opt/render/project/src/apps/ui/layers/account/layouts/onboarding.vue").then(m => m.default || m),
  "token-designer": () => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/token-designer.vue").then(m => m.default || m),
  tokenomics: () => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/tokenomics.vue").then(m => m.default || m),
  pathfinder: () => import("/opt/render/project/src/apps/ui/layers/pathfinder/layouts/pathfinder.vue").then(m => m.default || m),
  "auto-distribution-steps": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/auto-distribution-steps.vue").then(m => m.default || m),
  "automated-market-making-steps": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/automated-market-making-steps.vue").then(m => m.default || m),
  "automated-market-making": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/automated-market-making.vue").then(m => m.default || m),
  "listing-and-liquidity-step": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/listing-and-liquidity-step.vue").then(m => m.default || m),
  "market-maker-monitoring": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/market-maker-monitoring.vue").then(m => m.default || m)
}